@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/*==========  Reset  =========*/

/* https://piccalil.li/blog/a-modern-css-reset/ */

/* Box sizing rules */
*,
*::before,
*::after {
	padding: 0px;
	margin: 0px;
	box-sizing: border-box;
	transition: 0.4s;
	font-family: "Montserrat", sans-serif;
}

body {
	background-color: #f4f4f4;
	text-rendering: optimizeSpeed;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
	margin: 0;
}

/* Set core root defaults */
html:focus-within {
	scroll-behavior: smooth;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	-webkit-text-decoration-skip: ink;
	        text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
	max-width: 100%;
	display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
	html:focus-within {
		scroll-behavior: auto;
	}

	*,
	*::before,
	*::after {
		-webkit-animation-duration: 0.01ms !important;
		        animation-duration: 0.01ms !important;
		-webkit-animation-iteration-count: 1 !important;
		        animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

/*==========  App  =========*/
.pointer,
a,
button {
	cursor: pointer;
}

button:disabled {
	cursor: not-allowed;
}

.app {
	/* max-width: 1900px; */
	margin: 0 auto;
}

.layout {
	padding: 0rem 1rem;
}

/* DOWNLOAD TABLE */
.download_table {
	display: none;
}

@media screen and (max-width: 575px) {
	.layout {
		padding: 0rem 0.5rem;
	}
	h4 {
		font-size: 14px;
	}
	h1 {
		font-size: 28px;
	}
}

@media screen and (min-width: 992px) {
	body {
		overflow-y: hidden;
	}
}

.login {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	padding: 1rem;
}

.login__inner {
	--bs-gutter-x: 1rem;
	--bs-gutter-y: 0rem;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(var(--bs-gutter-y) * -1);
	margin-right: calc(var(--bs-gutter-x) * -0.5);
	margin-left: calc(var(--bs-gutter-x) * -0.5);
	height: 100%;
	width: 100%;
}

.login__inner > * {
	flex-shrink: 0;
	width: 100%;
	max-width: 100%;
	padding-right: calc(var(--bs-gutter-x) * 0.5);
	padding-left: calc(var(--bs-gutter-x) * 0.5);
	margin-top: var(--bs-gutter-y);
}

.login__form {
	flex: 0 0 auto;
	width: 30%;
}

.login__form__inner {
	background-color: #fff;
	box-shadow: 1px 1px 10px #1f1d591a;
	border-radius: 10px;
	min-height: 1px;
	height: 100%;
	padding: 0rem 2rem;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.login__form__inner2 {
	width: 100%;
}

.login__image {
	flex: 0 0 auto;
	width: 70%;
}

.login__image img {
	height: calc(100vh - 30px);
	width: 100%;
	border-radius: 10px;
	object-fit: cover;
}

.login_along_error {
	margin-top: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.login_along_error p {
	color: rgb(255, 56, 56);
	font-weight: 600;
}

.login__form__inner img {
	max-width: 150px;
}

.login__br {
	margin: 0.8rem 0rem;
}

.login__form__inner input {
	width: 100%;
	background-color: #e9edf2;
	height: 40px;
	border: none;
	border-radius: 6px;
	padding: 0rem 1rem;
	margin-top: 4px;
}

.login__form__inner input:focus {
	outline: none;
}

.login__btn {
	float: right;
	background-color: #22ad7a;
	border-radius: 10px;
	border: none;
	color: #fff;
	height: 40px;
	width: 85px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.login__btn.login__disabled {
	background-color: #22ad7a8c !important;
}

.login__inp__handle {
	position: relative;
}

.login__error__message {
	position: absolute;
	right: 0%;
	font-size: 12px;
	color: rgb(255, 56, 56);
	font-weight: 600;
}

.error_field {
	border: 2px solid rgb(255, 56, 56) !important;
}

@media screen and (max-width: 992px) {
	.login__form,
	.login__image {
		width: 50%;
	}
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 575px) {
	.login {
		padding-right: 0rem;
		padding-left: 0rem;
	}
	.login__form {
		width: 100%;
	}
	.login__image {
		display: none;
	}
}

.login__loading {
	height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.login__loading2 img,
.login__loading img {
	height: 100px;
	width: 100px;
	-webkit-animation: rotate-scale-down 0.65s linear infinite both;
	animation: rotate-scale-down 0.65s linear infinite both;
}

@-webkit-keyframes rotate-scale-down {
	0% {
		-webkit-transform: scale(1) rotateZ(0);
		transform: scale(1) rotateZ(0);
	}
	50% {
		-webkit-transform: scale(0.5) rotateZ(180deg);
		transform: scale(0.5) rotateZ(180deg);
	}
	100% {
		-webkit-transform: scale(1) rotateZ(360deg);
		transform: scale(1) rotateZ(360deg);
	}
}
@keyframes rotate-scale-down {
	0% {
		-webkit-transform: scale(1) rotateZ(0);
		transform: scale(1) rotateZ(0);
	}
	50% {
		-webkit-transform: scale(0.5) rotateZ(180deg);
		transform: scale(0.5) rotateZ(180deg);
	}
	100% {
		-webkit-transform: scale(1) rotateZ(360deg);
		transform: scale(1) rotateZ(360deg);
	}
}

.login__loading2 img {
	height: 22px;
	width: 22px;
}
.dashboard {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0rem;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(var(--bs-gutter-y) * -1);
	margin-right: calc(var(--bs-gutter-x) * -0.5);
	margin-left: calc(var(--bs-gutter-x) * -0.5);
	height: calc(100vh - 120px);
}

.dashboard > * {
	flex-shrink: 0;
	width: 100%;
	max-width: 100%;
	padding-right: calc(var(--bs-gutter-x) * 0.5);
	padding-left: calc(var(--bs-gutter-x) * 0.5);
	margin-top: var(--bs-gutter-y);
}

.dashboard__clients {
	flex: 0 0 auto;
	width: 30%;
}

.dashboard__challenges {
	flex: 0 0 auto;
	width: 70%;
}

.dashboard__clients__inner,
.dashboard__challenges__inner {
	background-color: #fff;
	box-shadow: 1px 1px 10px #1f1d5921;
	min-height: 1px;
	height: 100%;
	border-radius: 10px;
	padding: 25px;
}

.dashboard__clients__inner button {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	background-color: #22ad7a;
	color: #fff;
	border-radius: 6px;
	border: none;
	height: 40px;
	padding: 0rem 1rem;
	font-weight: bold;
	cursor: pointer;
}

.dashboard__clients__inner button span {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.dashboard__clients__header {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 2rem;
}

.dashboard__clients__header h2 {
	width: 40%;
}

.dashboard__clients__header h2 + div {
	width: 60%;
}

.dashboard__clients__header h2 + div img {
	float: right;
}

.dashboard__clients__body {
	margin-top: 1.2rem;
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - 270px);
	overflow-y: auto;
	padding: 0.2rem;
}

.inp_container {
	position: relative;
	width: 100%;
}

.inp_container input {
	height: 29px;
	padding: 0.4rem;
	width: 100%;
	border-radius: 4px;
	border: 2px solid #000;
}

.inp_container img {
	position: absolute;
	right: 0.5rem;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	width: 10px;
	cursor: pointer;
}

.dashboard__clients__body::-webkit-scrollbar {
	display: none;
}

.dashboard__challenges__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.dashboard__challenges__header a {
	color: #000000;
	font-weight: 600;
}

.dashboard__challenges__header a span {
	font-weight: 400;
}

.dashboard__challenges__header a span .bold {
	font-weight: 800;
}

.dashboard__challenges__header button {
	position: relative;
	display: flex;
	align-items: center;
	background-color: #1c395c;
	color: #fff;
	border-radius: 6px;
	border: none;
	height: 40px;
	padding: 0rem 1rem;
	font-weight: bold;
	width: 246px;
}

.dashboard__challenges__header button span {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.dashboard__challenges__body {
	margin-top: 3rem;
}

.dashboard__challenges__body__inner {
	max-height: calc(100vh - 270px);
	overflow: auto;
	overflow-x: hidden;
	padding-bottom: 2rem;

	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 1.5rem;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(var(--bs-gutter-y) * -1);
	margin-right: calc(var(--bs-gutter-x) * -0.5);
	margin-left: calc(var(--bs-gutter-x) * -0.5);
}

.dashboard__challenges__body__inner::-webkit-scrollbar {
	width: 7px;
}
.dashboard__challenges__body__inner::-webkit-scrollbar-track {
	background: #e2e6eb;
}
.dashboard__challenges__body__inner::-webkit-scrollbar-thumb {
	background: #1c395c;
	border-radius: 10px;
}

.dashboard__challenges__body__inner > * {
	flex-shrink: 0;
	width: 100%;
	max-width: 100%;
	padding-right: calc(var(--bs-gutter-x) * 0.5);
	padding-left: calc(var(--bs-gutter-x) * 0.5);
	margin-top: var(--bs-gutter-y);
}

.select-icon {
	max-width: 250px;
	margin-left: auto;
	margin-right: auto;
}

.dashboard__clients__header__search {
	display: none;
}

@media screen and (max-width: 992px) {
	.dashboard {
		--bs-gutter-y: 1.5rem;
	}
	.dashboard__clients {
		flex: 0 0 auto;
		width: 100%;
	}
	.dashboard__challenges {
		flex: 0 0 auto;
		width: 100%;
	}
}

@media screen and (max-width: 768px) {
	.dashboard__challenges__header {
		grid-gap: 1rem;
		gap: 1rem;
		flex-direction: column;
		align-items: unset;
	}
	.dashboard__challenges {
		padding-bottom: 1rem;
	}
}

@media screen and (max-width: 575px) {
	.dashboard__challenges__header button {
		width: 100%;
	}
	.dashboard__challenges__body h1 {
		font-size: 28px;
	}
}

.footer {
  position: absolute;
  bottom: 6px;
  font-size: 12px;
  padding: 4px 0;
  color: #00000087;
}

.minus_padding {
  /* margin-top: 7px; */
  color: #00000087;
  font-size: 12px;
  padding: 6px 0px;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	top: 0;
	height: 80px;
	margin-bottom: 0.5rem;
}

.header__logo {
	width: 175px;
	object-fit: contain;
}

.header__nav {
	display: flex;
	align-items: center;
	grid-gap: 2rem;
	gap: 2rem;
}

.header__option {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.header__option a {
	color: #000;
	text-decoration: none;
	cursor: pointer;
}

.header__option_outline {
	padding: 5px 5px 5px 10px;
	border-radius: 5px;
	border: 2px solid #000000;
}

.header__lockIcon {
	display: flex;
	align-items: center;
	height: 22px;
	margin-left: 0.5rem;
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
	.header__logo {
		width: 100px;
		object-fit: contain;
	}
	.header__option {
		font-size: 0.9rem;
	}
	.header__nav {
		grid-gap: 1rem;
		gap: 1rem;
	}
}

@media screen and (max-width: 575px) {
}

.client {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0.6rem 0rem;
	margin: 0.6rem 0rem;
	box-shadow: 1px 4px 10px #1f1d5923;
	padding: 0.8rem;
	border-radius: 8px;
	background-color: #fff;
	cursor: pointer;
}

.client__left {
	display: flex;
}

.client__left .client__left__text {
	margin-left: 1rem;
}

.client__left img {
	box-shadow: 1px 4px 5px #1f1d5934;
	background-color: #fff;
	padding: 0.5rem;
	border-radius: 8px;
	height: 45px;
	width: 45px;
}

.client__left h3 {
	margin-bottom: 0px;
	font-weight: bold;
}

.client__left p {
	margin-bottom: 0px;
	color: #555555;
	font-size: 14px;
}

@media screen and (max-width: 575px) {
	.client__left .client__left__text {
		margin-left: 0.8rem;
		padding-right: 0.4rem;
	}
	.single__challenge__header h4 {
		padding-right: 0.4rem;
	}
	.single__challenge__body__url,
	.single__challenge__body__number,
	.single__challenge__body a,
	.single__challenge ul li,
	.dashboard__challenges__header a span {
		font-size: 14px;
	}
	.dashboard__challenges__body h1 {
		padding-bottom: 5px;
	}
	.client__left h3 {
		margin-top: 4px;
		font-size: 16px;
	}
	.client__left p {
		margin-top: 3px;
		font-size: 13px;
	}
}

.single__challenge {
	flex: 0 0 auto;
	width: 50%;
}

.single__challenge__inner {
	box-shadow: 1px 1px 10px #e1e1ef;
	padding: 12px;
	border-radius: 8px;
}

.single__challenge ul {
	list-style: none;
	display: flex;
	padding: 2rem 0rem;
}

.single__challenge ul li {
	border: 1px solid #000;
	padding: 4px 12px;
	border-radius: 6px;
}

.single__challenge__header {
	display: flex;
	justify-content: space-between;
}

.single__challenge__body {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.single__Challenge__header__right {
	display: flex;
	grid-gap: 0.4rem;
	gap: 0.4rem;
}

.single__challenge__body__number {
	margin-left: 8px;
	background-color: rgba(0, 0, 0, 0.089);
	padding: 0.4rem 0.6rem;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
}

.single__challenge__body__copytext {
	position: absolute;
	background: #000;
	color: #fff;
	right: 22%;
	top: -2rem;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	font-size: 12px;
	padding: 5px 10px;
	border-radius: 5px;
}

.single__challenge__body__copytext::after {
	content: "";
	position: absolute;
	left: 50%;
	bottom: -0.4rem;
	-webkit-transform: translate(-50%);
	        transform: translate(-50%);
	height: 8px;
	width: 8px;
	background: #000;
	-webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
	        clip-path: polygon(50% 100%, 0 0, 100% 0);
	z-index: 1;
}

.single__challenge__body__url {
	font-weight: bold;
}

.single__challenge__body a {
	border: none;
	background-color: #a9dcf2;
	border-radius: 8px;
	padding: 0.4rem 0.8rem;
	text-decoration: none;
	color: #000;
	font-weight: 500;
}

@media screen and (max-width: 992px) {
	.single__challenge {
		flex: 0 0 auto;
		width: 100%;
	}
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 575px) {
}

.client__information {
	background: #ffffff;
	border-radius: 10px;
	padding: 25px;
	height: calc(100vh - 120px);
	box-shadow: 1px 2px 4px #1f1d5936;
}

.client__information__header {
	display: flex;
	justify-content: space-between;
}

.client__information__header ul li {
	cursor: pointer;
}

.client__information ul {
	display: flex;
	justify-content: space-between;
	align-items: center;
	list-style: none;
}

.client__information ul li {
	margin: 0px 10px;
}

.client__information ul li:nth-last-child(1) {
	margin: 0px;
}

.client__information ul li button {
	height: 40px;
}

.client__information ul li:nth-child(1) button {
	background: transparent;
	border: none;
	text-decoration: underline;
}

.client__information ul li:nth-child(2) button {
	background: #22ad7a;
	border-radius: 6px;
	border: none;
	padding: 0rem 1.5rem;
	color: #fff;
}

.client__information ul li:nth-child(3) button {
	background: #ce5252;
	border-radius: 6px;
	border: none;
	padding: 0rem 0.7rem;
	color: #fff;
}

.client__information__input {
	margin-top: 1rem;
}

.client__information__input input {
	background: #e9edf2;
	border: none;
	height: 38px;
	width: 100%;
	border-radius: 6px;
	margin-top: 0.5rem;
	padding: 0rem 1rem;
}

.client__information__input input:focus {
	outline: none;
}

.client__information__uploader {
	display: flex;
	align-items: center;
	margin-top: 2.5rem;
}

.client__information__uploader img {
	height: 120px;
	width: 120px;
	padding: 0.8rem;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 8px;
}

.client__information__uploader img.loader_img {
	height: 120px;
	width: 120px;
	object-fit: cover;
	padding: 0rem;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 8px;
}

.client__information__uploader__right {
	margin-left: 1rem;
}

.client__information__uploader__right p {
	margin-top: 0.4rem;
	font-size: 14px;
	font-weight: 500;
}

.client__information__uploader__right button {
	background-color: #1c395c;
	font-size: 14px;
	color: #fff;
	border-radius: 6px;
	height: 30px;
	padding: 0rem 1rem;
	border: none;
	cursor: pointer;
	margin-top: 10px;
}

.custom-file-input {
	color: transparent;
}

.custom-file-input::-webkit-file-upload-button {
	visibility: hidden;
}
.custom-file-input::before {
	content: "Upload icon";
	display: inline-block;
	background: #1c395c;
	outline: none;
	white-space: nowrap;
	-webkit-user-select: none;
	cursor: pointer;
	font-weight: 600;
	font-size: 14px;
	color: #fff;
	border-radius: 6px;
	padding: 0.5rem 1rem;
	border: none;
	cursor: pointer;
}
.custom-file-input:hover::before {
	border-color: none;
}
.custom-file-input:active::before {
	background: #1c395c;
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
	.client__information {
		padding: 15px;
	}
	.client__information__header {
		grid-gap: 0.6rem;
		gap: 0.6rem;
		flex-direction: column;
	}
	.client__information ul {
		justify-content: end;
	}
	.client__information__input {
		margin-top: 1.5rem;
	}
	.client__information__uploader img {
		height: 80px;
		width: 80px;
	}
}

@media screen and (max-width: 575px) {
	.client__information__header h2 {
		font-size: 22px;
	}
}

.popup {
	position: fixed;
	left: 0%;
	right: 0%;
	bottom: 0%;
	top: 0%;
	width: 100%;
	height: 100vh;
	background-color: #f4f4f4;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
}

.popup__inner {
	background-color: #fff;
	min-width: 450px;
	max-width: 450px;
	border-radius: 8px;
	box-shadow: 1px 1px 4px #1f1d591a;
}

.popup__header {
	display: flex;
	justify-content: space-between;
	background-color: #e2e6eb;
	padding: 1rem;
	border-radius: 8px 8px 0px 0px;
}

.popup__header p {
	font-size: 1.1rem;
}

.popup__body {
	padding: 1rem;
}

.pointer {
	cursor: pointer;
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
	.popup {
		padding: 0rem 1rem;
	}
	.popup__inner {
		min-width: 0px;
		width: 100%;
	}
}

@media screen and (max-width: 575px) {
}

.assignment {
	background: #ffffff;
	border-radius: 10px;
	padding: 25px;
	height: calc(100vh - 120px);
	box-shadow: 1px 2px 4px #1f1d5936;
}

.assignment__header {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.assignment__header ul {
	display: flex;
	grid-gap: 15px;
	gap: 15px;
	list-style: none;
}

.assignment__header a {
	color: #000;
}

.assignment__header ul li button {
	height: 40px;
}

.assignment__header button {
	height: 40px;
	background: transparent;
	border: none;
}

.assignment__header ul li:nth-child(1) button {
	background: #22ad7a;
	border-radius: 8px;
	border: none;
	padding: 0rem 0.8rem;
	color: #fff;
}

.assignment__header ul li:nth-child(2) button {
	background-color: #1c395c;
	font-size: 14px;
	color: #fff;
	border-radius: 6px;
	padding: 0rem 1rem;
	border: none;
}

.assignment__header ul li:nth-child(3) button {
	background-color: #e9d8ff;
	font-size: 14px;
	color: #fff;
	border-radius: 6px;
	padding: 0rem 0.6rem;
	border: none;
}

.assignment__header ul li:nth-child(3) button img {
	height: 25px;
}

.assignment__header ul li:nth-child(4) button {
	background-color: #d2e7ef;
	font-size: 14px;
	color: #fff;
	border-radius: 6px;
	padding: 0rem 0.5rem;
	border: none;
	cursor: pointer;
	height: 40px;
	width: 40px;
}

.assignment__title {
	display: flex;
	grid-gap: 4rem;
	gap: 4rem;
}

.assignment__title button {
	background: transparent;
	border: none;
	text-decoration: underline;
}

.assignment__title p {
	font-weight: 500;
}

.assignment__title p span {
	text-decoration: underline;
	font-weight: 600;
}

.assignment__list {
	margin-top: 2rem;
}

.assignment__list__inner {
	display: flex;
	flex-direction: column;
	grid-gap: 0.8rem;
	gap: 0.8rem;
	height: calc(100vh - 340px);
	overflow-y: auto;
	overflow-x: hidden;
	padding-right: 1rem;
}

.user__assignment__header__logo {
	display: flex;
	align-items: center;
	grid-gap: 1rem;
	gap: 1rem;
}

.assignment__list__inner::-webkit-scrollbar {
	width: 7px;
}
.assignment__list__inner::-webkit-scrollbar-track {
	background: #e2e6eb;
}
.assignment__list__inner::-webkit-scrollbar-thumb {
	background: #1c395c;
	border-radius: 10px;
}

.upload__assignment {
	padding: 2rem 0rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.upload__assignment img {
	margin-bottom: 1.2rem;
}

.upload__assignment p {
	background-color: #e9edf2;
	width: 100%;
	border-radius: 6px;
	height: 38px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.upload__assignment button {
	background-color: #1c395c;
	border-radius: 8px;
	height: 38px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	border: none;
	max-width: 220px;
	width: 100%;
}

.custom-file-input-upload {
	color: transparent;
	width: 100%;
	height: 52px;
}

.custom-file-input-upload::-webkit-file-upload-button {
	visibility: hidden;
}

.custom-file-input-upload:hover::before {
	border-color: none;
}
.custom-file-input-upload:active::before {
	background-color: #e9edf2;
}

.assignment__export__file {
	display: none;
}

@media screen and (max-width: 992px) {
	.assignment {
		padding: 18px;
	}
	.assignment__header {
		flex-direction: column;
		grid-gap: 1rem;
		gap: 1rem;
	}
	.assignment__header ul {
		justify-content: end;
	}
	.assignment__title {
		flex-direction: column;
		grid-gap: 1rem;
		gap: 1rem;
	}
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 575px) {
	.assignment__list__inner {
		height: calc(100vh - 400px);
		padding-bottom: 0.5rem;
	}
	.assignment__header {
		grid-gap: 0.5rem;
		gap: 0.5rem;
	}
	.assignment__title {
		grid-gap: 0.5rem;
		gap: 0.5rem;
	}
	.assignment__title p {
		font-size: 14px;
	}
	/* .user__assignment__header {
		flex-direction: column;
	} */
	.user__assignment__header__logo {
		grid-gap: 0.6rem;
		gap: 0.6rem;
		/* flex-direction: column;
		align-items: stretch; */
	}
	.user__assignment__header__logo h2 {
		font-size: 18px;
	}
}

.single__assignment {
	width: 100%;
	display: flex;
	grid-gap: 3rem;
	gap: 3rem;
	padding: 0.5rem 0.5rem 0.5rem 1.1rem;
	box-shadow: 1px 2px 6px #e1e1efd0;
	border-radius: 8px;
	border: 1px solid #e1e1ef;
}

.single__assignment ul {
	list-style: none;
}

.single__assignment .single__assignment__left {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.single__assignment .single__assignment__left div {
	display: flex;
	align-items: center;
	grid-gap: 1.5rem;
	gap: 1.5rem;
}

.single__assignment .single__assignment__left ul li {
	border: 1px solid #000;
	padding: 4px 12px;
	border-radius: 6px;
}

.single__assignment .single__assignment__left .single__assignment__left_h41 {
	color: #bababa;
	font-size: 25px;
	font-weight: 700;
}

.single__assignment__right img {
	height: 30px;
}

.single__assignment__right button {
	border: none;
	cursor: pointer;
	background: transparent;
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
	.single__assignment {
		padding: 0.4rem 0.4rem 0.4rem 0.8rem;
		grid-gap: 0.4rem;
		gap: 0.4rem;
	}
	.single__assignment .single__assignment__left div h4:nth-child(1) {
		font-size: 20px;
	}
	.single__assignment .single__assignment__left div h4:nth-child(2) {
		font-size: 16px;
	}
	.single__assignment .single__assignment__left div {
		grid-gap: 0.8rem;
		gap: 0.8rem;
	}
	.single__assignment .single__assignment__left ul li {
		font-size: 10px;
		border-radius: 4px;
	}
}

@media screen and (max-width: 575px) {
	.single__assignment {
		flex-direction: column;
		align-items: end;
	}
	.single__assignment .single__assignment__left div {
		grid-gap: 0.5rem;
		gap: 0.5rem;
	}
}

textarea {
	resize: none;
}

.add_edit_challenge {
	background: #ffffff;
	border-radius: 10px;
	padding: 25px;
	overflow: auto;
	box-shadow: 1px 2px 4px #1f1d5936;
}

.add_edit_challenge::-webkit-scrollbar {
	width: 7px;
	height: 4px;
}
.add_edit_challenge::-webkit-scrollbar-track {
	background: #e2e6eb;
}
.add_edit_challenge::-webkit-scrollbar-thumb {
	background: #1c395c;
	border-radius: 10px;
}

.add_edit_challenge {
	background: #ffffff;
	border-radius: 10px;
	padding: 25px;
	height: calc(100vh - 120px);
	box-shadow: 1px 2px 4px #1f1d5936;
}

.add_edit_challenge__header {
	display: flex;
	justify-content: space-between;
}

.add_edit_challenge .add_edit_challenge__header ul {
	display: flex;
	align-items: center;
	list-style: none;
	grid-gap: 1rem;
	gap: 1rem;
}

.add_edit_challenge .add_edit_challenge__header ul li:nth-last-child(1) {
	margin: 0px;
}

.add_edit_challenge .add_edit_challenge__header ul li button {
	height: 40px;
}

.add_edit_challenge .add_edit_challenge__header ul li:nth-child(1) button {
	background: transparent;
	border: none;
	text-decoration: underline;
}

.add_edit_challenge .add_edit_challenge__header ul li:nth-child(2) button {
	background: #22ad7a;
	border-radius: 6px;
	border: none;
	padding: 0rem 1.5rem;
	color: #fff;
}

.add_edit_challenge .add_edit_challenge__header ul li:nth-child(3) button {
	background: #ce5252;
	border-radius: 6px;
	border: none;
	padding: 0rem 0.7rem;
	color: #fff;
}

.add_edit_challenge .add_edit_challenge__header ul li:nth-child(4) button {
	background-color: #d2e7ef;
	font-size: 14px;
	color: #fff;
	border-radius: 6px;
	padding: 0rem 0.6rem;
	border: none;
}

.add_edit_challenge__body {
	margin-top: 1.5rem;
	display: flex;
	flex-direction: column;
}

.add_edit_challenge__body_row_one,
.add_edit_challenge__body_row_two,
.add_edit_challenge__body_row_three,
.add_edit_challenge__body_row_four {
	--bs-gutter-x: 1rem;
	--bs-gutter-y: 1rem;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(var(--bs-gutter-y) * -1);
	margin-right: calc(var(--bs-gutter-x) * -0.5);
	margin-left: calc(var(--bs-gutter-x) * -0.5);
	margin-left: 0px;
	height: 100%;
	width: 100%;
}

.add_edit_challenge__body_row_one > *,
.add_edit_challenge__body_row_two > *,
.add_edit_challenge__body_row_three > *,
.add_edit_challenge__body_row_four > * {
	flex-shrink: 0;
	width: 100%;
	max-width: 100%;
	padding-right: calc(var(--bs-gutter-x) * 0.5);
	padding-left: calc(var(--bs-gutter-x) * 0.5);
	margin-top: var(--bs-gutter-y);
}

.add_edit_challenge__body_row_one div {
	flex: 0 0 auto;
	width: 25%;
}

.add_edit_challenge__body_row_two div.add_edit_challenge__body_row_two_col {
	flex: 0 0 auto;
	width: 50%;
}

.add_edit_challenge__body_row_three div.add_edit_challenge__body_row_three_col {
	flex: 0 0 auto;
	width: 100%;
}

.add_edit_challenge__body_row_one div label,
.add_edit_challenge__body_row_two div label,
.add_edit_challenge__body_row_three div label,
.add_edit_challenge__body_row_four div label {
	font-weight: 500;
}

.add_edit_challenge__body_row_one div input,
.add_edit_challenge__body_row_two div textarea,
.add_edit_challenge__body_row_three div textarea,
.add_edit_challenge__body_row_four div textarea {
	margin-top: 0.2rem;
	width: 100%;
	background-color: #e9edf2;
	height: 40px;
	border: none;
	border-radius: 6px;
	padding: 1rem;
}

.add_edit_challenge__body_row_one div input[type="color"] {
	padding: 0rem;
	cursor: pointer;
	background-color: #fff;
	margin-top: 0rem;
	height: 44px;
	border-radius: 6px;
}

.add_edit_challenge__body_row_two,
.add_edit_challenge__body_row_three,
.add_edit_challenge__body_row_four {
	margin-top: 0.4rem;
}

.add_edit_challenge__body_row_two div textarea,
.add_edit_challenge__body_row_four div textarea {
	min-height: 150px;
	max-height: 150px;
}

.add_edit_challenge__body_row_three div textarea {
	min-height: 70px;
	max-height: 70px;
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
	.add_edit_challenge {
		padding: 15px;
	}
	.add_edit_challenge__header {
		grid-gap: 0.6rem;
		gap: 0.6rem;
		flex-direction: column;
	}
	.add_edit_challenge ul {
		justify-content: end;
	}
	.add_edit_challenge__body_row_one,
	.add_edit_challenge__body_row_two,
	.add_edit_challenge__body_row_three,
	.add_edit_challenge__body_row_four {
		--bs-gutter-x: 0rem;
		--bs-gutter-y: 1rem;
		display: flex;
		flex-wrap: wrap;
		margin-top: calc(var(--bs-gutter-y) * -1);
		margin-right: calc(var(--bs-gutter-x) * -0.5);
		margin-left: calc(var(--bs-gutter-x) * -0.5);
		height: 100%;
		width: 100%;
	}
	.add_edit_challenge__body_row_one div {
		width: 100%;
	}
	.add_edit_challenge__body_row_two,
	.add_edit_challenge__body_row_three,
	.add_edit_challenge__body_row_four {
		margin-top: 0rem;
	}
	.add_edit_challenge__body_row_two div.add_edit_challenge__body_row_two_col {
		flex: 0 0 auto;
		width: 100%;
	}
}

@media screen and (max-width: 575px) {
	.add_edit_challenge__header h2 {
		font-size: 22px;
	}
}

.user__challenge {
  padding: 1.2rem 1.4rem;
  height: 100vh;
}

.user__challenge__inner {
  padding: 3rem;
  height: calc(100vh - 60px);
  background-color: #fff;
  border-radius: 8px;
}

.user__challenge__inner .user__challenge__header {
  margin-bottom: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.user__challenge__inner .user__challenge__header img.user__challenge__icon {
  height: 120px;
  width: 120px;
  border-radius: 6px;
}

.user__challenge__inner .user__challenge__header ul {
  list-style: none;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.user__challenge__inner .user__challenge__header ul li:nth-child(1) button {
  background: transparent;
  border: 6px;
  height: 38px;
  border: none;
  padding: 0rem 1.2rem;
  border-radius: 6px;
  color: #000;
  cursor: pointer;
  text-decoration: underline;
}

.user__challenge__inner .user__challenge__header ul li:nth-child(2) button {
  background: #22ad7a;
  border: 6px;
  height: 38px;
  border: none;
  padding: 0rem 1.2rem;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
}

.user__challenge__inner .user__challenge__header ul li:nth-child(3) button {
  background: #ffc65a;
  border: 6px;
  height: 38px;
  width: 38px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user__challenge__inner h3 {
  margin-bottom: 3rem;
}

.user__challenge__body {
  max-height: calc(100vh - 385px);
  overflow-y: auto;
  white-space: pre-line;
  padding-right: 1rem;
}

.user__challenge .user__challenge__body ul {
  list-style: inside !important;
}

.user__challenge .user__challenge__body ol {
  list-style: inside decimal !important;
}

.user__challenge__body::-webkit-scrollbar {
  width: 7px;
}
.user__challenge__body::-webkit-scrollbar-track {
  background: #e2e6eb;
}
.user__challenge__body::-webkit-scrollbar-thumb {
  background: #1c395c;
  border-radius: 10px;
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  .user__challenge {
    padding: 0.8rem 0.6rem;
  }
  .user__challenge__inner {
    padding: 1rem;
    height: calc(100vh - 28px);
  }
  .user__challenge__inner .user__challenge__header img.user__challenge__icon {
    height: 70px;
    width: 70px;
  }
  .user__challenge__body {
    max-height: calc(100vh - 270px);
  }
}

@media screen and (max-width: 575px) {
  .user__challenge__inner .user__challenge__header ul li:nth-child(1) button {
    padding: 0rem 0rem;
  }
}

.editor_container {
	padding: 10px;
	background-color: #e9edf2;
	border-radius: 6px;
	margin-top: 0.2rem;
}

.editor_container * {
	font-family: inherit;
}

.rdw-editor-main {
	min-height: 200px;
	max-height: 200px;
}

.rdw-image-modal,
.rdw-emoji-modal,
.rdw-embedded-modal,
.rdw-link-modal {
	right: 0%;
	left: auto;
}

.rdw-embedded-modal {
	height: 194px;
}

.rdw-link-modal {
	height: 238px;
}

.rdw-editor-main::-webkit-scrollbar,
.rdw-dropdown-optionwrapper::-webkit-scrollbar {
	width: 5px;
}
.rdw-editor-main::-webkit-scrollbar-track,
.rdw-dropdown-optionwrapper::-webkit-scrollbar-track {
	background: #e2e6eb;
}
.rdw-editor-main::-webkit-scrollbar-thumb,
.rdw-dropdown-optionwrapper::-webkit-scrollbar-thumb {
	background: #1c395c;
	border-radius: 10px;
}

textarea {
	resize: none;
}

.add_edit_assignment {
	background: #ffffff;
	border-radius: 10px;
	padding: 25px;
	height: calc(100vh - 120px);
	overflow: auto;
	box-shadow: 1px 2px 4px #1f1d5936;
}

.add_edit_assignment::-webkit-scrollbar {
	width: 7px;
}
.add_edit_assignment::-webkit-scrollbar-track {
	background: #e2e6eb;
}
.add_edit_assignment::-webkit-scrollbar-thumb {
	background: #1c395c;
	border-radius: 10px;
}

.add_edit_assignment {
	background: #ffffff;
	border-radius: 10px;
	padding: 25px;
	height: calc(100vh - 120px);
	box-shadow: 1px 2px 4px #1f1d5936;
}

.add_edit_assignment__header {
	display: flex;
	justify-content: space-between;
}

.add_edit_assignment .add_edit_assignment__header ul {
	display: flex;
	align-items: center;
	list-style: none;
	grid-gap: 1rem;
	gap: 1rem;
}

.add_edit_assignment .add_edit_assignment__header ul li:nth-last-child(1) {
	margin: 0px;
}

.add_edit_assignment .add_edit_assignment__header ul li button {
	height: 40px;
}

.add_edit_assignment .add_edit_assignment__header ul li:nth-child(1) button {
	background: transparent;
	border: none;
	text-decoration: underline;
}

.add_edit_assignment .add_edit_assignment__header ul li:nth-child(2) button {
	background: #22ad7a;
	border-radius: 6px;
	border: none;
	padding: 0rem 1.5rem;
	color: #fff;
}

.add_edit_assignment .add_edit_assignment__header ul li:nth-child(3) button {
	background: #ce5252;
	border-radius: 6px;
	border: none;
	padding: 0rem 0.7rem;
	color: #fff;
}

.add_edit_assignment .add_edit_assignment__header ul li:nth-child(4) button {
	background-color: #d2e7ef;
	font-size: 14px;
	color: #fff;
	border-radius: 6px;
	padding: 0rem 0.6rem;
	border: none;
}

.add_edit_assignment__body {
	margin-top: 1.5rem;
	display: flex;
	flex-direction: column;
}

.add_edit_assignment__body_row_one,
.add_edit_assignment__body_row_two,
.add_edit_assignment__body_row_three,
.add_edit_assignment__body_row_four {
	--bs-gutter-x: 1rem;
	--bs-gutter-y: 1rem;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(var(--bs-gutter-y) * -1);
	margin-right: calc(var(--bs-gutter-x) * -0.5);
	margin-left: calc(var(--bs-gutter-x) * -0.5);
	height: 100%;
	width: 100%;
}

.add_edit_assignment__body_row_one > *,
.add_edit_assignment__body_row_two > *,
.add_edit_assignment__body_row_three > *,
.add_edit_assignment__body_row_four > * {
	flex-shrink: 0;
	width: 100%;
	max-width: 100%;
	padding-right: calc(var(--bs-gutter-x) * 0.5);
	padding-left: calc(var(--bs-gutter-x) * 0.5);
	margin-top: var(--bs-gutter-y);
}

.add_edit_assignment__body_row_one div {
	flex: 0 0 auto;
	width: 50%;
}

.add_edit_assignment__body_row_two div.add_edit_assignment__body_row_two_col,
.add_edit_assignment__body_row_three
	div.add_edit_assignment__body_row_three_col {
	flex: 0 0 auto;
	width: 100%;
}

.add_edit_assignment__body_row_four .col-2 {
	flex: 0 0 auto;
	width: 25%;
}

.add_edit_assignment__body_row_four .col-8 {
	flex: 0 0 auto;
	width: 50%;
}

.add_edit_assignment__body_row_four div div {
	flex: 0 0 auto;
	width: 100%;
}

.add_edit_assignment__body_row_one div label,
.add_edit_assignment__body_row_two div label,
.add_edit_assignment__body_row_three div label,
.add_edit_assignment__body_row_four div label {
	font-weight: 500;
}

.add_edit_assignment__body_row_one div input,
.add_edit_assignment__body_row_two div textarea,
.add_edit_assignment__body_row_three div textarea,
.add_edit_assignment__body_row_four div input {
	margin-top: 0.2rem;
	width: 100%;
	background-color: #e9edf2;
	height: 40px;
	border: none;
	border-radius: 6px;
	padding: 1rem;
}

.add_edit_assignment__body_row_four div input[type="file"] {
	margin-top: 0rem;
	width: 100%;
	background-color: transparent;
	height: 36px;
	border: none;
	border-radius: 0px;
	padding: 0px;
}

.add_edit_assignment__body_row_four button {
	background-color: #1c395c;
	font-size: 14px;
	color: #fff;
	border-radius: 6px;
	padding: 0rem 1rem;
	border: none;
	height: 36px;
	margin-bottom: 0.5rem;
}

.add_edit_assignment__body_row_four p {
	font-size: 10px;
	font-weight: 500;
}

.add_edit_assignment__body_row_four div {
	position: relative;
}

.add_edit_assignment__body_row_four img {
	max-height: 50px;
	width: 70px;
	border: 1px solid #000;
	border-radius: 6px;
	object-fit: cover;
}

.add_edit_assignment__body_row_four__right_inp {
	display: flex;
	align-items: center;
	grid-gap: 1rem;
	gap: 1rem;
}

.add_edit_assignment__body_row_two,
.add_edit_assignment__body_row_three,
.add_edit_assignment__body_row_four {
	margin-top: 0.4rem;
}

.add_edit_assignment__body_row_two div textarea,
.add_edit_assignment__body_row_four div textarea {
	min-height: 230px;
	max-height: 230px;
}

.add_edit_assignment__body_row_three div textarea {
	min-height: 120px;
	max-height: 120px;
}

.delete__assignment {
	padding: 2rem 0rem;
	text-align: center;
}

.delete__assignment div {
	margin: 1rem 0rem;
	display: flex;
	justify-content: center;
	grid-gap: 1.4rem;
	gap: 1.4rem;
}

.delete__assignment div button:nth-child(1) {
	background: #ce5252;
	height: 38px;
	padding: 0rem 1rem;
	color: #fff;
	border-radius: 6px;
	border: none;
}

.delete__assignment div button:nth-child(2) {
	text-decoration: underline;
	color: #000;
	background: transparent;
	border: none;
	font-weight: 500;
}

.pass_inp {
	position: relative;
}

.pass_info_tooltip {
	position: absolute !important;
	bottom: 105%;
	right: 0%;
	background: #ce5252;
	border-radius: 6px;
	padding: 0.6rem;
	max-width: 130px;
	float: right;
	color: #fff;
	font-size: 13px;
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
	.add_edit_assignment {
		padding: 15px;
	}
	.add_edit_assignment__header {
		grid-gap: 0.6rem;
		gap: 0.6rem;
		flex-direction: column;
	}
	.add_edit_assignment .add_edit_assignment__header ul {
		justify-content: end;
	}
	.add_edit_assignment__body_row_one,
	.add_edit_assignment__body_row_two,
	.add_edit_assignment__body_row_three,
	.add_edit_assignment__body_row_four {
		--bs-gutter-x: 0rem;
		--bs-gutter-y: 1rem;
		display: flex;
		flex-wrap: wrap;
		margin-top: calc(var(--bs-gutter-y) * -1);
		margin-right: calc(var(--bs-gutter-x) * -0.5);
		margin-left: calc(var(--bs-gutter-x) * -0.5);
		height: 100%;
		width: 100%;
	}
	.add_edit_assignment__body_row_one div,
	.add_edit_assignment__body_row_two div.add_edit_assignment__body_row_two_col,
	.add_edit_assignment__body_row_four .col-2,
	.add_edit_assignment__body_row_four .col-8 {
		width: 100%;
	}
	.add_edit_assignment__body_row_two,
	.add_edit_assignment__body_row_three,
	.add_edit_assignment__body_row_four {
		margin-top: 0rem;
	}
}

@media screen and (max-width: 575px) {
	.add_edit_assignment__body_row_four div div {
		width: 120px;
	}
}

.user__assignment {
  padding: 0.8rem 1rem;
}

.user__assignment__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 84px;
  background: white;
  border-radius: 6px;
  padding: 7px;
  margin-bottom: 13px;
}

.user__assignment__header img {
  padding: 0.5rem;
  height: 70px;
  width: 70px;
  border-radius: 8px;
}

.user__assignment__header .user__btn__container button:nth-child(1) {
  height: 40px;
  padding: 0rem 1.5rem;
  background: #d2e7ef;
  border: none;
  border-radius: 6px;
  color: #000;
  font-weight: 700;
}

.user__assignment__header .user__btn__container button:nth-child(2) {
  margin-left: 1rem;
  height: 40px;
  padding: 0rem 1.5rem;
  background: #1c395c;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-weight: 600;
}

.user__assignment__body {
  --bs-gutter-x: 1.2rem;
  --bs-gutter-y: 0rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  overflow-y: auto;
  padding-bottom: 1rem;
}

.user__assignment__body > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.user__assignment__body__left {
  flex: 0 0 auto;
  width: 65%;
}

.user__assignment__body__right {
  flex: 0 0 auto;
  width: 35%;
}

.user__assignment__body__left__inner,
.user__assignment__body__right__inner {
  background: #fff;
  height: calc(100vh - 148px);
  border-radius: 6px;
  padding: 1.2rem;
  box-shadow: 0px 1px 4px #1f1d5921;
}

.user__assignment__body__right__inner
  p.user__assignment__body__right__inner__description {
  max-height: calc(100vh - 535px);
  overflow-y: auto;
  padding-right: 0.2rem;
  white-space: pre-line;
}

.user__assignment__body__left__inner__description {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  padding-right: 0.2rem;
  white-space: pre-line;
}

.user__dashboard__header__logo {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.user__assignment__body__right__inner
  p.user__assignment__body__right__inner__description::-webkit-scrollbar,
.user__assignment__body__left__inner__description::-webkit-scrollbar {
  width: 7px;
}
.user__assignment__body__right__inner
  p.user__assignment__body__right__inner__description::-webkit-scrollbar-track,
.user__assignment__body__left__inner__description::-webkit-scrollbar-track {
  background: #e2e6eb;
}
.user__assignment__body__right__inner
  p.user__assignment__body__right__inner__description::-webkit-scrollbar-thumb,
.user__assignment__body__left__inner__description::-webkit-scrollbar-thumb {
  background: #1c395c;
  border-radius: 10px;
}

.user__assignment__body__left__inner div {
  margin-top: 2rem;
}

.user__assignment__body__right__inner__img {
  background: #ebebeb;
  border-radius: 6px;
  max-height: 325px;
  height: 100%;
  width: 100%;
}

.user__assignment__body__right__inner__img img {
  object-fit: contain;
  border-radius: 6px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.user__btn__container {
  padding: 10px;
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  .user__assignment__body__left,
  .user__assignment__body__right {
    flex: 0 0 auto;
    width: 100%;
  }
  .user__assignment__body {
    --bs-gutter-y: 1rem;
  }
  .user__assignment__body__left__inner,
  .user__assignment__body__right__inner {
    height: 100%;
  }
  .user__assignment__body__left__inner div {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 575px) {
  .user__assignment__header .user__btn__container button:nth-child(1) {
    height: 34px;
    padding: 0rem 0.8rem;
    font-size: 14px;
    width: 100%;
  }
  .user__assignment__header .user__btn__container button:nth-child(2) {
    margin-left: 0px;
    font-size: 14px;
  }
  .user__btn__container {
    display: flex;
    /* flex-direction: column; */
    grid-gap: 0.6rem;
    gap: 0.6rem;
    align-items: center;
  }
  .user__assignment__header img {
    height: 60px;
    width: 60px;
  }
  .user__assignment__header {
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: stretch;
  }
  .user__assignment__body__right__inner
    p.user__assignment__body__right__inner__description {
    max-height: 300px;
  }
  .user__assignment__body__left__inner,
  .user__assignment__body__right__inner {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

.no_data_container {
  height: calc(100vh - 340px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.user__dashboard {
  padding: 0.8rem 1rem;
  height: 100vh;
}

.user__dashboard__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 84px;
  background: white;
  border-radius: 6px;
  padding: 7px;
  margin-bottom: 13px;
}

/* .user__dashboard__header .client_img {
	box-shadow: 1px 2px 6px #e1e1ef;
} */

.user__dashboard__header img {
  border-radius: 6px;
  padding: 0.5rem;
  height: 70px;
  width: 70px;
}

.user__dashboard__header .assignment_right {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 10px;
}

.user__dashboard__header button {
  background: #ffc65a;
  height: 46px;
  width: 46px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.user__dashboard__header button img {
  background: #ffc65a;
  height: 46px;
  width: 46px;
}

.user__dashboard__body__inner {
  --bs-gutter-x: 1rem;
  --bs-gutter-y: 1rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -0);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  max-height: calc(100vh - 140px);
  overflow-y: auto;
  padding-bottom: 1rem;
}

.user__dashboard__body__inner::-webkit-scrollbar {
  width: 7px;
}
.user__dashboard__body__inner::-webkit-scrollbar-track {
  background: #e2e6eb;
}
.user__dashboard__body__inner::-webkit-scrollbar-thumb {
  background: #1c395c;
  border-radius: 10px;
}

.user__dashboard__body__inner > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: 0;
  margin-bottom: var(--bs-gutter-y);
}

.password__assignment {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  grid-gap: 1.4rem;
  gap: 1.4rem;
  padding: 1.2rem 0rem;
}

.password__assignment input {
  width: 100%;
  background-color: #e9edf2;
  height: 40px;
  border: none;
  border-radius: 6px;
  padding: 1rem;
}

.password__assignment img {
  padding: 0.4rem;
  background-color: #ffc65a;
  border-radius: 6px;
  height: 40px;
  cursor: pointer;
}

.password__assignment button {
  background: #22ad7a;
  border-radius: 6px;
  height: 38px;
  padding: 0rem 1.6rem;
  color: #fff;
  border: none;
  font-weight: 600;
}

.password__instruction__assignment {
  padding: 1.5rem 0rem;
}

.support__instruction__assignment {
  max-height: 400px;
  overflow: auto;
  /* padding: 1.2rem 0rem; */
}

.support__instruction__assignment::-webkit-scrollbar {
  width: 7px;
}
.support__instruction__assignment::-webkit-scrollbar-track {
  background: #e2e6eb;
}
.support__instruction__assignment::-webkit-scrollbar-thumb {
  background: #1c395c;
  border-radius: 10px;
}

.support__instruction__assignment .support__instruction__assignment__header {
  white-space: pre-line;
}

.support__instruction__assignment .support__instruction__assignment__body {
  margin-top: 0.6rem;
}

.support__instruction__assignment
  .support__instruction__assignment__body::-webkit-scrollbar {
  width: 7px;
}
.support__instruction__assignment
  .support__instruction__assignment__body::-webkit-scrollbar-track {
  background: #e2e6eb;
}
.support__instruction__assignment
  .support__instruction__assignment__body::-webkit-scrollbar-thumb {
  background: #1c395c;
  border-radius: 10px;
}

@media screen and (max-width: 575px) {
  .user__dashboard__header .assignment_right h2 {
    display: none;
  }
  .user__dashboard__header__logo h2 {
    font-size: 18px;
  }
  .user__dashboard__header img {
    height: 60px;
    width: 60px;
  }
  .user__dashboard__header__logo {
    grid-gap: 0.6rem;
    gap: 0.6rem;
  }
}

.single__assignment__user {
  flex: 0 0 auto;
  width: 20%;
}

.single__assignment__user__inner {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  min-height: 1px;
  height: 100%;
  min-height: 1px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 375px;
}

.single__assignment__user__inner p {
  font-weight: 600;
  margin-top: 0.8rem;
}

.single__assignment__user__inner ul li {
  border: 1px solid #000;
  padding: 4px 12px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
}

.single__assignment__user__inner ul {
  display: flex;
  list-style: none;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.single__assignment__user__inner button {
  height: 40px;
  width: 100%;
  border-radius: 10px;
  border: none;
  background: #22ad7a;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.single__assignment__user__image {
  background: #ebebeb;
  height: 195px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single__assignment__user__image img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  object-position: top;
  /* opacity: 0.5; */
}

.password__assignment .inp_container {
  position: relative;
  height: 100%;
}

.password__assignment__top {
  display: flex;
  width: 100%;
  grid-gap: 0.8rem;
  gap: 0.8rem;
}

.password__assignment .inp_container .error_message {
  position: absolute;
  right: 0%;
  bottom: -16px;
  background: transparent;
  color: rgb(255, 56, 56);
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: end;
  font-weight: 600;
}

@media screen and (max-width: 992px) {
  .single__assignment__user {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
}

@media screen and (max-width: 768px) {
  .single__assignment__user {
    flex: 0 0 auto;
    width: 50%;
  }
}

@media screen and (max-width: 575px) {
  .single__assignment__user {
    flex: 0 0 auto;
    width: 100%;
  }
  .single__assignment__user__inner {
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
  }
  .single__assignment__user__image img {
    object-fit: cover;
    object-position: top;
  }
  .single__assignment__user__image {
    height: 200px;
  }
}

