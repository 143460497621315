@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/*==========  Reset  =========*/

/* https://piccalil.li/blog/a-modern-css-reset/ */

/* Box sizing rules */
*,
*::before,
*::after {
	padding: 0px;
	margin: 0px;
	box-sizing: border-box;
	transition: 0.4s;
	font-family: "Montserrat", sans-serif;
}

body {
	background-color: #f4f4f4;
	text-rendering: optimizeSpeed;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
	margin: 0;
}

/* Set core root defaults */
html:focus-within {
	scroll-behavior: smooth;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
	max-width: 100%;
	display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
	html:focus-within {
		scroll-behavior: auto;
	}

	*,
	*::before,
	*::after {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

/*==========  App  =========*/
.pointer,
a,
button {
	cursor: pointer;
}

button:disabled {
	cursor: not-allowed;
}

.app {
	/* max-width: 1900px; */
	margin: 0 auto;
}

.layout {
	padding: 0rem 1rem;
}

/* DOWNLOAD TABLE */
.download_table {
	display: none;
}

@media screen and (max-width: 575px) {
	.layout {
		padding: 0rem 0.5rem;
	}
	h4 {
		font-size: 14px;
	}
	h1 {
		font-size: 28px;
	}
}

@media screen and (min-width: 992px) {
	body {
		overflow-y: hidden;
	}
}
