.single__assignment__user {
  flex: 0 0 auto;
  width: 20%;
}

.single__assignment__user__inner {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  min-height: 1px;
  height: 100%;
  min-height: 1px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 375px;
}

.single__assignment__user__inner p {
  font-weight: 600;
  margin-top: 0.8rem;
}

.single__assignment__user__inner ul li {
  border: 1px solid #000;
  padding: 4px 12px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
}

.single__assignment__user__inner ul {
  display: flex;
  list-style: none;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.single__assignment__user__inner button {
  height: 40px;
  width: 100%;
  border-radius: 10px;
  border: none;
  background: #22ad7a;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.single__assignment__user__image {
  background: #ebebeb;
  height: 195px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single__assignment__user__image img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  object-position: top;
  /* opacity: 0.5; */
}

.password__assignment .inp_container {
  position: relative;
  height: 100%;
}

.password__assignment__top {
  display: flex;
  width: 100%;
  gap: 0.8rem;
}

.password__assignment .inp_container .error_message {
  position: absolute;
  right: 0%;
  bottom: -16px;
  background: transparent;
  color: rgb(255, 56, 56);
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: end;
  font-weight: 600;
}

@media screen and (max-width: 992px) {
  .single__assignment__user {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
}

@media screen and (max-width: 768px) {
  .single__assignment__user {
    flex: 0 0 auto;
    width: 50%;
  }
}

@media screen and (max-width: 575px) {
  .single__assignment__user {
    flex: 0 0 auto;
    width: 100%;
  }
  .single__assignment__user__inner {
    max-height: fit-content;
  }
  .single__assignment__user__image img {
    object-fit: cover;
    object-position: top;
  }
  .single__assignment__user__image {
    height: 200px;
  }
}
